
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedStaff: null
}

const staffs = (state = initialState, action) => {  
  switch (action.type) {    
    case 'GET_STAFFS_DATA':
      return {
        ...state,
        allData: action.data.items,
        total: action.data.total,
        last_page: action.data.last_page
      }
    case 'GET_STAFF':
      return { ...state, selectedStaff: action.selectedStaff }
    case 'UPDATE_STAFF':
      return { ...state, allData: action.allData }
    case 'ADD_STAFF':
      return { ...state, allData: action.allData }
    case 'DELETE_STAFF':
      return { ...state, allData: action.allData }
    case 'OPEN_STAFF_DELETE_MODAL':
      return { ...state, del_modal_status: action.del_modal_status }
    case 'LOGOUT':
      return { ...initialState }
    default:
      return { ...state }
  }
}
export default staffs
