import { paginateArray } from "../../../../../utility/Utils"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const users = (state = initialState, action) => {
  let allData = {}
  switch (action.type) {
   
    case 'GET_USERS_DATA':
      allData = action.allData
      const { q = '', perPage = 10, page = 1, role = null, is_active = 2 } = action.params
      const queryLowered = q.toLowerCase()
      const filteredData = allData.filter(
        user => (
          user.username.toLowerCase().includes(queryLowered) || user.email.toLowerCase().includes(queryLowered)) &&
          user.role === (role || user.role) &&
          (+user.is_active) === (is_active === 2 ?  +user.is_active : is_active)
      )
      return {
        ...state,
        data: paginateArray(filteredData, perPage, page),
        total: filteredData.length,
        params: action.params,
        allData: action.allData
      }    
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }    
    case 'UPDATE_USER':
      return { ...state, allData: action.allData }
    case 'ADD_USER':
      return { ...state, allData: action.allData }
    case 'DELETE_USER':
      return { ...state, allData: action.allData }
    case 'OPEN_USER_DELETE_MODAL':
      return { ...state, del_modal_status: action.del_modal_status }
    case 'LOGOUT':
      return { ...initialState }
    default:
      return { ...state }
  }
}
export default users
